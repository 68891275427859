<div class="lp-header" [style.background]="lp.cor_header">
      <div class="container">
            <div class="row">
                  <div class="col-lg-4 col-md-6 col-3 center">
                        <figure class="logo" (click)="toHome()">
                              <img [src]="lp.logo" [alt]="lp.nome" width="220px" />
                        </figure>
                  </div>
                  <div class="col-md-4 col-form-pesquisa">
                        <!--<box-form-pesquisa class="center"></box-form-pesquisa>-->
                  </div>  
                  <div class="col-lg-4 col-md-6 col-9"> 
                        <div class="lista-actions-content center">
                            <ul class="lista-actions">
                                <li>
                                    <btn-usuario-login></btn-usuario-login>
                                </li>  
                                <li class="li-btn-cart">
                                    <btn-cart></btn-cart>
                                </li>
                            </ul> 
                        </div>     
                  </div> 
            </div>
      </div>
      <div class="container">
            <!--<box-form-pesquisa class="mobile"></box-form-pesquisa>-->
      </div>
</div>