<div class="product-item {{classCustom}}" (click)="_click()">
    <div class="capa" (click)="_click()" *ngIf="lazy">
      <a *ngIf="produto.foto != null" itemprop="url" title="Saiba mais sobre: {{produto.nome}}">
        <img width="100%"  defaultImage="/images/produto/sem-foto/default-capa.jpg" [lazyLoad]="produto.foto" itemprop="image" [alt]="produto.nome" class="img-fluid" /> 
      </a>  
      <a *ngIf="produto.foto == null" itemprop="url" title="Saiba mais sobre: {{produto.nome}}">  
        <img width="100%" itemprop="image" src="/images/produto/sem-foto/default-capa.jpg" alt="Sem Foto" class="img-fluid" />
      </a>   
    </div>
    <div class="capa" (click)="_click()" *ngIf="!lazy">
      <a *ngIf="produto.foto != null" itemprop="url" title="Saiba mais sobre: {{produto.nome}}">
        <img width="100%" [src]="produto.foto" itemprop="image" [alt]="produto.nome" class="img-fluid" /> 
      </a>  
      <a *ngIf="produto.foto == null" itemprop="url" title="Saiba mais sobre: {{produto.nome}}">  
        <img width="100%" itemprop="image" src="/images/produto/sem-foto/default-capa.jpg" alt="Sem Foto" class="img-fluid" />
      </a>   
    </div>
    <div class="content">
      <div class="content-body">
        <h4>{{produto.nome | max: '40'}}</h4>
        <div class="info">
          <div class="item" *ngIf="produto.duracao != null">
              <div class="icon">
                  <i class="fa-solid fa-clock"></i>
              </div>
              <span>{{produto.duracao | formatDuracao}} (duração aproximada)</span>
          </div>
          <div class="item" *ngIf="produto.dias.length > 0">
              <div class="icon">
                  <i class="fa-solid fa-calendar-day"></i>
              </div>
              <span>{{produto.dias | formatDias}}</span>
          </div> 
        </div>
      </div>
      <div class="footer">
        <div class="content-valor">
          <produto-item-valor template="two" [servico]="produto" [valor]="produto.menor_tarifa"></produto-item-valor>
        </div> 
      </div> 
      <div class="installments" *ngIf="produto.mostrar_parcelas">Parcele em até <strong>{{produto.parcelas}} vezes</strong></div>     
    </div> 
</div>   