import { Component, OnInit, Input, AfterViewInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { NavigationEnd, NavigationStart, Router,Event as NavigationEvent } from '@angular/router';
import { AppService } from 'src/app/services/app.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'partial-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit{

  
  @Input("classCustom") classCustom = "";
  @Input("template") template       = "default";
  @ViewChild("content") content:any; 
  @ViewChild("close") close:any; 
  @ViewChild("overlay") overlay:any;   
  public show = false;
  public menuCategoria:any = [];

  constructor(
    public data: DataService,
    public router: Router,
    public app: AppService
  ){ }
  

  /**
   * 
   * Open
   * 
   */
  _open(){

    if(this.show){

      this.content.nativeElement.classList.remove("slide-right");
      this.content.nativeElement.classList.add("slide-left");

      setTimeout(() => {
        this.content.nativeElement.classList.remove("show");
      },200);
      this.overlay.nativeElement.classList.remove("show");
      this.close.nativeElement.classList.remove("show");
      this.show = false;

    }else{

      this.content.nativeElement.classList.add("slide-right");

      setTimeout(() => {
        this.content.nativeElement.classList.add("show");
      },200);

      this.content.nativeElement.classList.remove("slide-left"); 
      this.overlay.nativeElement.classList.add("show");
      this.close.nativeElement.classList.add("show");
      this.show = true;

    }

  }
  /**
   * 
   * Close
   * 
   */
  _close(){

      if(this.app.isMobile()){
        
     
      this.content.nativeElement.classList.remove("slide-right");
      this.content.nativeElement.classList.add("slide-left");

      setTimeout(() => {
        this.content.nativeElement.classList.remove("show");
      },200);
      this.overlay.nativeElement.classList.remove("show");
      this.close.nativeElement.classList.remove("show");
      this.show = false;

      }

  }
  /**
   * 
   * On resize
   * 
   */
   onResize(){

    if(window.innerWidth >= 959){
      this.content.nativeElement.classList.remove("slide-right");
      this.content.nativeElement.classList.remove("slide-left");
    }else{
      this.classCustom = "mobile";
    }

  }

  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.menuCategoria = this.data.getCategoriasMenu();
  }
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.onResize();
    },300);
  }


}
