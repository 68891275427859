<header class="header template-default">
    <div class="content">
        <div class="container">
            <div class="row">
                <div class="col-md-4 col-6">
                    <modulo posicao="logo"></modulo>  
                </div>
                <div class="col-md-8 content-actions col-6">
                    <div class="actions">
                    <ul>
                        <li>
                            <div class="content-search" (click)="openSearch()">
                                <div class="icon">
                                    <i class="fa-solid fa-magnifying-glass"></i>
                                </div>
                                <span>Pesquisar</span>
                            </div>
                        </li>
                        <li>
                            <btn-cart></btn-cart>
                        </li>
                        <li>
                            <btn-usuario-login [usuarioService]="usuarioService"></btn-usuario-login>
                        </li>
                    </ul>
                </div>      
                </div>
            </div>    
        </div>     
    </div>
    <div class="content-menu">
        <div class="container">
            <partial-menu [classCustom]="classCustom"></partial-menu>
        </div>
    </div>    
</header>  