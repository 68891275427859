<div class="menu-partial {{classCustom}} template-{{template}}" (window:resize)="onResize()">
    <button type="button" class="btn-open" (click)="_open()">
        <i class="fa-solid fa-bars"></i>
    </button>  
    <div class="close" #close (click)="_open()">  
        <img src="images/icons/close-01.svg" width="50px" height="50px" alt="Close" />
    </div>    
    <div class="content" #content> 
        <div class="header-logo">
            <img src="images/logos/imove-logo.svg" width="100px" alt="Logo" />
        </div>
        <ul>
            <li *ngFor="let item of menuCategoria">
                <a routerLink="/servicos/{{item.apelido}}" title="Passeios" (click)="_close()">
                    <div class="icon">
                        <img [src]="item.icone" [alt]="item.titulo" width="30px" height="30px" />
                    </div>
                    <span>{{item.titulo}}</span>
                </a>    
            </li>
            <li>
                <a routerLink="/sobre" title="Sobre Nó" (click)="_close()">
                    <div class="icon">
                        <img src="images/icons/heart.svg" alt="Sobre Nós" height="30px" />
                    </div>
                    <span>Sobre Nós</span>
                </a>    
            </li>
            <li>
                <a routerLink="/eventos" title="Eventos" (click)="_close()">
                    <div class="icon">
                        <img src="images/icons/eventos.svg" alt="Eventos" height="30px" />
                    </div>
                    <span>Eventos</span>
                </a>    
            </li>
            <li>
                <a routerLink="/contato" title="Contato" (click)="_close()">
                    <div class="icon">
                        <img src="images/icons/contato.svg" alt="Contato" height="30px" />
                    </div>
                    <span>Contato</span>
                </a>    
            </li>
        </ul> 
    </div>   
</div> 
<div class="overlay" #overlay></div>   
