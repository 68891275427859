import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { UsuarioService } from 'src/app/services/usuario.service';
import { BoxPesquisaModalComponent } from '../../box-pesquisa/box-pesquisa-modal/box-pesquisa-modal.component';

declare var $:any;

@Component({
  selector: 'partial-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit,AfterViewInit {
 
  @ViewChild("header") header:ElementRef;
  public showMenu = new EventEmitter();
  public classCustom = "";

  constructor(
    public usuarioService: UsuarioService,
    public dialog: MatDialog
  ) { }
  
  /**
   * 
   * Open search
   * 
   */
  openSearch(){

    this.dialog.open(
      BoxPesquisaModalComponent,{
        width: "800px"
      }
    );

  }
  /***
   * 
   * On data
   * 
   **/
   _showMenu(){

    this.showMenu.emit(null);  

  }
  /**
   * 
   * Seta a Class Fixed para o topo
   * 
   */
  setTopFixed(){

    if(this.header != null && this.header != undefined){

      let header = this.header.nativeElement;

      $(window).scroll(function(){
          
          var fromTop = $(this).scrollTop();
          var windowWidth = $(window).width();
          
          if(fromTop > 100 && windowWidth > 960){
            header.classList.add("animated","fadeIn","fixed");
          }else{
            header.classList.remove("fixed","animated","fadeIn");
          }

      });

    }
  
  }
  /**
   * 
   * On resize
   * 
   */
  onResize(){

    if(window.innerWidth >= 767){
      this.classCustom = "";
    }else{
      this.classCustom = "mobile";
    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    
  }
  /**
   * 
   * Init
   * 
   */
  ngAfterViewInit(): void {
    this.onResize();
    this.setTopFixed();
  }

}
