import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';

declare var $:any;

@Component({
  selector: 'banner-slider-slick',
  templateUrl: './banner-slider-slick.component.html',
  styleUrls: ['./banner-slider-slick.component.scss']
})
export class BannerSliderSlickComponent implements OnInit,AfterViewInit {
  
  @Input("data")  data               = null; 
  @Input("template")  template       = null; 
  @Input("classCustom")  classCustom = null; 
  @ViewChild("banner") banner:ElementRef;
  public banners       = []; 
  public width:any = 0;

  constructor(
    private route: Router
  ){

  }
  /**
   * 
   * Select banner
   * 
   **/
  selectBanner(i){

    $(this.banner.nativeElement).slick("goTo",i);

  }
  /**
   * 
   * Init slider
   * 
   */
  initBanner(){

    $(this.banner.nativeElement).slick({ 
      infinite: true,
      slidesToShow: 1,
      dots: true, 
      arrows: false,
      centerMode: false,
      autoplay: true,
      autoplaySpeed: 2500,
      centerPadding: '0',
      responsive: [{
          breakpoint: 1224,
          settings: {
            slidesToShow: 1,  
          }
      },{
          breakpoint: 1024,
          settings: {
          slidesToShow: 1,
        }
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,

          }
      },
      {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            centerPadding: '0',
          }
      }
    ]
    
    }); 

    $(this.banner.nativeElement).slick('refresh'); 

  }
  /**
   * 
   * Retorna o link do produto
   * 
   */
  getLinkProduto(banner){

    return "/servicos/"+banner.categoria_apelido+"/"+banner.produto_apelido;

  }
  /**
   * 
   * Retorna o link do produto
   * 
   */
  getLinkCategoria(banner){

    return "/servicos/"+banner.categoria;

  }
  /**
   * 
   * To cidades
   * 
   */
  toCidades(){

    this.route.navigateByUrl("/a-serra-gaucha");   
    
  }
  /**
   * 
   * Prev
   * 
   */
  prev(){

    $(this.banner.nativeElement).slick("slickPrev");

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.banner.nativeElement).slick("slickNext"); 
  
  }
  /**
   * 
   * On resize
   * 
   */
  onResize(event:any){

    this.width = window.innerWidth;
    
    if(typeof(this.banner.nativeElement) != "undefined"){
      $(this.banner.nativeElement).slick('refresh');  
    }

  }
  /***
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.width = window.innerWidth;
  }
  /**
   * 
   * After View loaded
   * 
   */
  ngAfterViewInit(): void {
    this.initBanner();
  }

}
