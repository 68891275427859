<div class="lp-footer" [style.background]="lp.cor_header">
      <div class="content">
      
      </div>
      <div class="copy">
            Todos os direitos reservados - Gramado Receptivo - 2023 | Desenvolvido por 
            <a href="https://www.digitalmovement.com.br" target="_blank">
                  Digital Movement 
                  <img src="/assets/digital-movement/logo-digital-movement-2.svg" alt="Digital Movement - Soluções em Tecnologia" width="30px" height="30px" />
            </a>. 
      </div>
</div>