<div class="module-whatsapp" (click)="open()" *ngIf="template == 'fixo'">
    <div class="content">
        <div class="header">
            <div class="icon-left">
                <i class="fa-brands fa-whatsapp"></i>
            </div>    
            <span>Fale com um <br>especialista?</span> 
        </div>
    </div>   
</div>   
<div class="module module-whatsapp-full" *ngIf="template == 'full'">
    <div class="container">
        <div class="title wow slideInDown">
            <h3>{{data.titulo}}</h3>
        </div>
        <div class="description wow slideInUp">
            <p>
                {{data.subtitulo}}
            </p>
        </div>
        <div class="content-button">
            <button type="button" class="btn-one wow slideInRight" (click)="open()">
                <span>Enviar mensagem</span>
                <div class="icon">
                    <i class="fa-brands fa-whatsapp"></i>
                </div>
            </button>
        </div>
    </div>    
</div> 