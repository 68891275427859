import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'modulo-gatilho-mental',
  templateUrl: './gatilho-mental.component.html',
  styleUrls: ['./gatilho-mental.component.scss']
})
export class GatilhoMentalComponent implements OnInit {

  @Input("data") data:any           = {};
  @Input("template") template:any   = "";
  @Input("classCustom") classCustom = "";
  public isMobile:any = false;

  constructor(
    public app: AppService
  ) { }

  /**
   * 
   * Iniciliza as Funções
   * 
   */
  ngOnInit():void{
    this.isMobile = this.app.isMobile();
  }


}
