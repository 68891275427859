<div class="app-content" *ngIf="ativo && !isLp"> 
    <partial-header></partial-header>
    <router-outlet></router-outlet>   
    <partial-footer></partial-footer>
</div>  
<div class="app-content" *ngIf="ativo && isLp">
    <lp-header></lp-header>
    <router-outlet></router-outlet> 
    <lp-footer></lp-footer>  
</div>  
<div class="app-content site-desativado" *ngIf="!ativo"> 
    <div class="content">
        <span class="title">Estamos em manutenção</span>
        <span class="subtitle">E breve traremos novidades.</span>
    </div>
</div>


