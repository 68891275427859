import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ApiService } from 'src/app/services/api.service';
import { NavigationEnd, NavigationStart, Router,Event as NavigationEvent } from '@angular/router';

@Component({
  selector: 'box-pesquisa-modal',
  templateUrl: './box-pesquisa-modal.component.html',
  styleUrls: ['./box-pesquisa-modal.component.scss']
})
export class BoxPesquisaModalComponent implements OnInit {

  public dataItem:any  = {};
  public loader        = false;
  public _data         = null;

  constructor(
    public dialog: MatDialogRef<BoxPesquisaModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    public api: ApiService,
    private router: Router
  ){}

  /**
   * 
   * Get search
   * 
   */
  getData(){

    try{

      this.loader = true;

      this.api.produto().search(this.dataItem).subscribe(response => {

        this.loader = false;

        if(response.status == 1){
          this._data = response.data.produtos;
        }else{
          this._data = [];
        }

      },(response) => {

        this.loader = false;
        this._data  = [];

      });

    }catch(e){

      this.loader = false;


    }


  }
  /**
   * 
   * Search
   * 
   */
  _search(){

    if(this.dataItem.termo.length >= 3){

      this.getData();

    }else{
      this._data = null;
    }

  }
  /**
   * 
   * Init data item
   * 
   */
  initDataItem(){

    this.dataItem = {
      termo: "",
      termo_categoria: true,
      produto_categoria_id: null
    }

  }
  /**
   * 
   * On Data
   * 
   */
  onData(){

    this.router.events.forEach((event: NavigationEvent) => {
      
      if(event instanceof NavigationStart){
        setTimeout(() => {
          this.dialog.close();
        },500);
      }
      if(event instanceof NavigationEnd) {

      }

    });

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.initDataItem();
    this.onData();
  }

}
