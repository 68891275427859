<section class="page-home">  
  <modulo posicao="banner-slider"></modulo>
  <modulos-destaque></modulos-destaque> 
  <modulo posicao="whatsapp" template="fixo"></modulo> 
</section>   

 



