<div class="modulo modulo-produto-categoria-destaque">
      <div class="modulo-titulo">
        <div class="container">
          <h2 class="page-custom-title title" [innerHtml]="data.titulo | safeHtml"></h2>
          <h3 class="subtitle" *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h3>
        </div>
      </div>  
      <div class="modulo-content">
            <div class="section-content">
                  <div class="container"> 
                    <div class="produtos slider-itens" #slider>   
                      <div class="slider-item item-servicos" *ngFor="let item of data.data"> 
                        <produto-item [data]="item"></produto-item>    
                      </div>    
                    </div> 
                    <div class="slide-navigate-item prev" (click)="prev()">
                      <div class="content">
                        <i class="material-icons">navigate_before</i>
                      </div>
                    </div>
                    <div class="slide-navigate-item next" (click)="next()">
                      <div class="content">
                        <i class="material-icons">navigate_next</i>
                      </div>
                    </div>
                  </div>
            </div>  
      </div>
      <div class="footer-content" *ngIf="data.data.length > 0">
             <div class="container">
                <a [href]="link" title="Ver todos" class="btn-all" (click)="toRoute()">
                    <span>Ver todos</span>
                    <div class="icon">
                        <i class="material-icons">navigate_next</i>
                        <i class="material-icons">navigate_next</i>
                        <i class="material-icons">navigate_next</i>
                    </div>
                </a> 
            </div>
      </div>
</div>