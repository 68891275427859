<div class="module module-products">
      <div class="module-title" *ngIf="data.mostrar_titulo">
          <div class="container">
              <div class="title">
                  <h2 class="wow slideInLeft">{{data.titulo}}</h2>
                  <h3 class="wow slideInUp">{{data.subtitulo}}</h3>
              </div>
          </div>    
      </div>
      <div class="module-content">
            <div class="container"> 
                <div class="products slider-items" #slider>  
                    <div class="slider-item" *ngFor="let p of data.data">
                        <produto-item-two [data]="p"></produto-item-two>  
                    </div>
                </div>
                <div class="slide-navigate-item prev" (click)="prev()">
                    <img width="30px" height="30px" src="/images/icons/prev.svg" alt="Prev" />
                </div>
                <div class="slide-navigate-item next" (click)="next()">
                    <img width="30px" height="30px" src="/images/icons/next.svg" alt="Next" />
                </div> 
            </div>
            <div class="content-button">
                <button class="btn-one wow slideInUp" (click)="toRoute()">
                    <span>Ver mais atrações</span>
                    <div class="icon">
                        <i class="fa-solid fa-forward"></i>
                    </div>
                </button>
            </div>
        </div>   
</div>      