<div class="dm-modal">
      <div class="dm-modal-title">
        <div class="close" (click)="dialog.close()">
          <img width="12px" defaultImage="/images/pixel/bg.png" src="/images/template/icons/close.svg" alt="close" />
        </div>    
      </div> 
      <div class="dm-modal-content">   
            <form class="form form-custom">
                  <label>Faça a sua pesquisa aqui:</label>
                  <div class="form-group"> 
                        <input type="text" (input)="_search()" class="input-search" placeholder="Passeios, transfer, ingressos e muito mais..." [(ngModel)]="dataItem.termo" name="termo" />
                        <div class="loader" *ngIf="loader">
                              <div class="lds-dual-ring"></div>
                        </div>      
                  </div>
            </form>
            <div class="content-search box-sombra" *ngIf="_data != null">
                  <div class="content" *ngIf="_data.length > 0">   
                        <div class="item" *ngFor="let d of _data">
                              <produto-item-three [data]="d"></produto-item-three>  
                        </div>
                  </div>
                  <div class="content" *ngIf="_data.length == 0">
                        <p>Não econtramos atrações com o termo <strong>"{{dataItem.termo}}"</strong></p>
                  </div>
            </div>  
      </div>    
</div>    
  