<div [ngSwitch]="template">
    <div *ngSwitchCase="'default'" class="valor default {{classCustom}}" itemprop="offers" itemscope itemtype="http://schema.org/Offer">
      <div class="valor-number" *ngIf="valor > 0">
        <div class="content tariff" *ngIf="!servico.promocao">
          <div class="box">
            <span class="a-partir">a partir de</span>
          </div>
          <div class="box box-valor">
            <span class="cifrao">R$</span>
            <span class="main" itemprop="price">{{valor1}},{{valor2}}</span> 
          </div>
        </div>  
        <div class="content _promocao" *ngIf="servico.promocao"> 
          <div class="box tariff">
            <div class="valor-promocao">
              <span><strong>R$ {{valor}}</strong></span><br>
            </div>
            <div class="box-valor">
              <span class="cifrao">R$ </span>
              <span class="main" itemprop="price">{{valorPromocao1}},{{valorPromocao2}}</span> 
            </div>
          </div>
        </div>  
      </div>
      <div class="valor-number content tariff" *ngIf="valor == 0">
        <div class="box">
          <span class="a-partir">a partir de:</span>
        </div>
        <div class="box box-valor">
          <span  class="consulte" itemprop="price">CONSULTE!</span>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'servico'" class="valor default default-servico {{classCustom}}" itemprop="offers" itemscope itemtype="http://schema.org/Offer">
      <div class="valor-number" *ngIf="valor > 0">
        <div class="content" *ngIf="!servico.promocao">
          <div class="box">
            <span class="a-partir">a partir de:</span>
          </div>
          <div class="box box-valor content-total">
            <div class="total">
              <span class="cifrao" itemprop="priceCurrency">R$</span>
              <strong class="main" itemprop="price">{{valor1}}</strong> 
              <span>,{{valor2}}</span>
            </div>
          </div>
        </div>  
        <div class="content _promocao" *ngIf="servico.promocao"> 
          <div class="box">
            <div class="valor-promocao">
              <strong>R$ {{valor}}</strong>
            </div>
            <div class="box-valor">
              <span class="cifrao" itemprop="priceCurrency">R$</span>
              <span class="main" itemprop="price">{{valorPromocao1}}</span> 
              <span>,{{valorPromocao2}}</span>
            </div>
          </div>
        </div>  
      </div>
      <div class="valor-number content" *ngIf="valor == 0">
        <div class="box">
          <span class="a-partir">{{'a partir de' | trabslate}}:</span>
        </div>
        <div class="box">
          <span class="consulte" itemprop="price">{{'CONSULTE' | translate}}!</span>
        </div>
      </div>
    </div>
    <div *ngSwitchCase="'cart'" class="valor valor-cart {{classCustom}}" itemprop="offers" itemscope itemtype="http://schema.org/Offer">
      <span class="a-partir">{{'Valor' | translate}}</span>  
      <div class="content box-valor">    
        <span class="cifrao">R$</span>
        <span class='main'>{{valor1}}</span> 
        <span>,{{valor2}}</span>
      </div>
    </div>
    <div *ngSwitchCase="'two'" class="valor valor-two" itemprop="offers" itemscope itemtype="http://schema.org/Offer">
      <div class="tariff" [ngClass]="{'consulte': valor == 0}" *ngIf="!servico.promocao">  
        <span class="a-partir">A partir de:</span>
        <strong class="value" *ngIf="valor > 0">  
            <span class="cifrao">R$ </span>
            {{valor1}},{{valor2}}
        </strong>  
        <strong class="value" *ngIf="valor == 0">  
          CONSULTE!
        </strong>
      </div>
      <div class="tariff _promocao" *ngIf="servico.promocao"> 
        <span class="a-partir">A partir de:</span>
        <div class="box">
          <div class="valor-promocao">
            <strong>R$ {{valor}}</strong>
          </div>
          <strong class="value">  
            <span class="cifrao">R$ </span>
            {{valorPromocao1}},{{valorPromocao2}}
          </strong>  
        </div>
      </div>
    </div>
</div>  
  