<div class="module-mental-trigger">
      <div class="container">
          <ul class="list" *ngIf="!isMobile">
              <li *ngFor="let i of data.items">
                  <div class="item wow slideInUp">
                      <div class="icon">
                          <img [src]="i.icone" [alt]="i.titulo" height="50px" />
                      </div>
                      <h3 [innerHtml]="i.titulo"></h3>
                      <article [innerHtml]="i.descricao"></article>
                  </div>
              </li>
          </ul>
          <div class="wp-layout-grid" *ngIf="isMobile">
            <div class="wp-layout-item" *ngFor="let i of data.items">
                <div class="item">
                    <div class="icon">
                        <img [src]="i.icone" [alt]="i.titulo" height="50px" />
                    </div>
                    <h3 [innerHtml]="i.titulo"></h3>
                    <article [innerHtml]="i.descricao"></article>
                </div>
            </div>
          </div>
      </div>
  </div>
  