<div [ngSwitch]="template">
    <div class="btn-login" *ngSwitchCase="null" [ngClass]="{'template-lp': lp != null}">
        <div class="item item-user" *ngIf="usuario == null">
            <div class="account-user" (click)="_open()" >
                <div class="icon">
                    <i class="fa-regular fa-user"></i>
                </div>
                <div class="text">
                    <span>MINHA</span><br>
                    <span>CONTA</span>
                </div>
            </div>
        </div>
        <div class="content content-usuario" (click)="toRoute('/minha-conta/perfil')" *ngIf="usuario !== null">
            <a href="javascript::void();" class="foto btn-header-action">
                <div class="item">
                    Olá,<br>
                    <strong>{{nome}}</strong>
                </div>
            </a>
        </div>       
    </div>
    <div class="btn-login two" *ngSwitchCase="'two'"> 
        <button type="button" (click)="_open()">
            <span>Já tenho uma conta</span>
        </button> 
    </div>
    <div class="btn-login three" *ngSwitchCase="'three'">   
        <button type="button" (click)="_open(1)">
            <span>Clique Aqui</span>
        </button> 
    </div>     
</div>        
