import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'modulo-evento',
  templateUrl: './modulo-evento.component.html',
  styleUrls: ['./modulo-evento.component.scss']
})
export class ModuloEventoComponent implements OnInit {

  @Input("data") data:any = {};
  public params:any = {};

  constructor(
    public router: Router
  ){}

  /**
   * 
   * To route
   * 
   */
  toRoute(){

    this.router.navigateByUrl("/eventos");
    return false;

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.params = JSON.parse(this.data.params);
  }

}
