<div class="banner-slider module-banner-slider" (window:resize)="onResize($event)">
  <div class="banners slider-itens" #banner>
    <div class="item slider-item" *ngFor="let f of data.banners;let i=index;">
      <div *ngIf="!f.link_ativo">
        <img [src]="f.imagem" [alt]="f.titulo"  width="100%" *ngIf="width > 700" />
        <img [src]="f.imagem_mobile" [alt]="f.titulo"  width="100%" *ngIf="width <= 700" />
      </div>
      <div *ngIf="f.link_ativo" [ngSwitch]="f.link_tipo">
        <a [routerLink]="getLinkCategoria(f)" *ngSwitchCase="'3'">
          <div class="item slider-item">
            <img [src]="f.imagem" [alt]="f.titulo"  width="100%" *ngIf="width > 700" />
          <img [src]="f.imagem_mobile" [alt]="f.titulo"  width="100%" *ngIf="width <= 700" />
          </div>  
        </a>
        <a [routerLink]="getLinkProduto(f)" *ngSwitchCase="'2'">
          <div class="item slider-item">
            <img [src]="f.imagem" [alt]="f.titulo"  width="100%" *ngIf="width > 700" />
            <img [src]="f.imagem_mobile" [alt]="f.titulo"  width="100%" *ngIf="width <= 700" />
          </div>  
        </a> 
        <a [href]="f.link" [target]="f.link_target" *ngSwitchCase="'1'">
          <div class="item slider-item">
            <img [src]="f.imagem" [alt]="f.titulo"  width="100%" *ngIf="width > 700" />
            <img [src]="f.imagem_mobile" [alt]="f.titulo"  width="100%" *ngIf="width <= 700" />
          </div>  
        </a>  
      </div>
    </div>    
  </div> 
  <!--<div class="navigate">
    <div class="navigate-item prev" (click)="prev()">
        <div class="content">
            <img src="images/icons/prev-01.svg" alt="Prev" width="40px" />
        </div>
    </div> 
    <div class="navigate-item next" (click)="next()">
        <div class="content">
            <img src="images/icons/next-01.svg" alt="Next" width="40px" />
        </div>
    </div>    
  </div>--> 
</div>


