<div class="module module-products">
  <div class="module-title">
    <div class="container">
      <h2 class="wow slideInUp" data-wow-delay="0.5s" [innerHtml]="data.titulo | safeHtml"></h2>
      <h3 class="wow slideInDown" data-wow-delay="0.6s">{{data.subtitulo}}</h3>
    </div>
  </div>  
  <div class="modulo-content content">
    <div class="section">
      <div class="section-content">
        <div class="container"> 
          <div class="produtos slider-itens" #slider>   
            <div class="slider-item item-servicos" *ngFor="let item of data.data"> 
              <produto-item [data]="item"></produto-item>    
            </div>    
          </div> 
          <div class="slide-navigate-item prev" (click)="prev()">
            <img width="30px" height="30px" src="/images/icons/prev.svg" alt="Prev" />
          </div>
          <div class="slide-navigate-item next" (click)="next()">
              <img width="30px" height="30px" src="/images/icons/next.svg" alt="Next" />
          </div>  
        </div>
      </div>  
    </div>   
  </div> 
  <div class="content-button">
    <a [routerLink]="link" title="Ver todos os transfers" class="btn-one wow slideInRight">
        <span>Ver todos</span>
        <div class="icon">
            <i class="fa-solid fa-forward"></i>
        </div>
    </a> 
  </div>
</div>
 
