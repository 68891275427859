<div class="module module-events">
      <div class="container">
          <div class="row">
              <div class="col-md-5">
                  <h2 class="wow slideInUp" [innerHtml]="data.descricao | safeHtml"></h2>
                  <div class="logo wow slideInLeft">
                      <img src="/images/modulo/logo/{{params.logo}}" alt="Logo" width="150px" />
                  </div>
                  <div class="linha"></div>
                  <div class="content-button">
                      <button type="button" class="btn-two" (click)="toRoute()">
                          <span>ver mais sobre eventos</span>
                      </button>
                  </div>
              </div>
              <div class="col-md-7">
                  <div class="capa">
                      <img src="/images/modulo/evento/{{params.foto_evento}}" alt="Evento" width="100%" />
                  </div>
                  <article class="description" [innerHtml]="params.descricao_evento | safeHtml"></article>
              </div>
          </div>
      </div>
</div>
  