<div class="module-newsletter">
  <div class="content">
      <div class="container">
          <div class="row">
              <div class="col-md-6">
                  <div class="box-title">
                      <h4 *ngIf="data.mostrar_titulo">{{data.titulo}}</h4>
                      <h5 *ngIf="data.mostrar_subtitulo">{{data.subtitulo}}</h5>
                  </div>
              </div>
              <div class="col-md-6">  
                  <form class="form-custom" (submit)="send()">
                      <div class="content-input">  
                          <div class="item-input" data-wow-delay="0.3s">
                              <div class="icon">
                                  <img src="/images/icons/user-name.svg" alt="User Name" width="20px" />
                              </div>
                              <input type="text" [(ngModel)]="dataItem.nome" name="nome" placeholder="Informe seu nome" />
                          </div>    
                          <div class="item-input" data-wow-delay="0.3s">
                              <div class="icon">
                                  <img src="/images/icons/user-email-01.svg" alt="User Name" width="20px" />
                              </div>
                              <input type="text" [(ngModel)]="dataItem.email" name="email" placeholder="Informe seu email" />    
                          </div> 
                      </div>  
                      <div class="content-button">  
                          <button type="button" class="btn-one"> 
                              <span>Enviar</span>
                          </button>
                      </div>   
                  </form> 
              </div>
          </div>   
      </div>
  </div>
</div>    

<loader *ngIf="loader"></loader>
